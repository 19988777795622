export * from './insights/insights';
export * from './crmUpdates';
export * from './testRunModal/testRunModal';
export * from './hooks/useTestRuns';
export * from './insightFormModal/insightFormModal';
export * from './hooks/useActivityFieldsInfo';
export * from './markdownReader/markdownReader';
export * from './hooks/useActivityFieldsInfo';

export const isTranscriptActivity = (activityType: string) =>
  ['Call', 'Meeting'].includes(activityType);
