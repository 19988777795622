import { ColorType, IconType } from '@bloobirds-it/flamingo-ui';

import { BobjectId, BobjectTypes, MainBobjectTypes } from './bobjects';

export enum TaskTypeName {
  ProspectCadence = 'Prospect Cadence',
  NextStep = 'Next Step',
  ContactBeforeMeeting = 'Contact before meeting',
}

export enum TasksHookFamilies {
  Calendar = 'Calendar',
  Home = 'Home',
}

export interface TaskDate {
  hashDate: string;
  prefix: string;
  formattedDate: string;
  isFirstOfDay: boolean;
}

export enum TASK_AUTOMATED_STATUS_LOGIC_ROLE {
  PENDING = 'TASK__AUTOMATED_STATUS__PENDING',
  COMPLETED = 'TASK__AUTOMATED_STATUS__COMPLETED',
  FAILED = 'TASK__AUTOMATED_STATUS__FAILED',
  PAUSED = 'TASK__AUTOMATED_STATUS__PAUSED',
  RESCHEDULED = 'TASK__AUTOMATED_STATUS__RESCHEDULED',
}

export enum TASK_AUTOMATED_ERROR_LOGIC_ROLE {
  MISSING_EMAIL = 'TASK__AUTOMATION_ERROR__MISSING_EMAIL',
  OPT_OUT_ENABLED = 'TASK__AUTOMATION_ERROR__OPT_OUT_ENABLED',
  PAUSED_MORE_THAN_7_DAYS = 'TASK__AUTOMATION_ERROR__PAUSED_MORE_THAN_7_DAYS',
  STOPPED_ACCOUNT = 'TASK__AUTOMATION_ERROR__STOPPED_ACCOUNT',
  UNKNOWN_ERROR = 'TASK__AUTOMATION_ERROR__UNKNOWN_ERROR',
  VARIABLE_NOT_RESOLVED = 'TASK__AUTOMATION_ERROR__MISSING_VARIABLE',
  DAILY_LIMIT_EXCEEDED = 'TASK__AUTOMATION_ERROR__DAILY_LIMIT_EXCEEDED',
  INVALID_RECIPIENT = 'TASK__AUTOMATION_ERROR__INVALID_RECIPIENT',
}

export enum TASK_AUTOMATED_PAUSED_REASONS_LOGIC_ROLE {
  OVERDUE = 'TASK__AUTOMATION_PAUSE_REASON__OVERDUE',
  TOUCH = 'TASK__AUTOMATION_PAUSE_REASON__TOUCH',
}

export enum TASK_FIELDS_LOGIC_ROLE {
  ASSIGNED_TO = 'TASK__ASSIGNED_TO',
  AUTOMATED_STATUS = 'TASK__AUTOMATED_STATUS',
  AUTOMATION_ERROR = 'TASK__AUTOMATION_ERROR',
  AUTOMATION_PAUSE_REASON = 'TASK__AUTOMATION_PAUSE_REASON',
  CADENCE = 'TASK__CADENCE',
  COMPANY = 'TASK__COMPANY',
  COMPLETED_DATE = 'TASK__COMPLETED_DATE',
  CREATION_DATETIME = 'TASK__CREATION_DATETIME',
  DESCRIPTION = 'TASK__DESCRIPTION',
  EMAIL_METADATA = 'TASK__EMAIL_METADATA',
  IS_AUTOMATED = 'TASK__IS_AUTOMATED',
  LEAD = 'TASK__LEAD',
  OPPORTUNITY = 'TASK__OPPORTUNITY',
  SCHEDULED_DATE = 'TASK__SCHEDULED_DATE',
  SCHEDULED_DATETIME = 'TASK__SCHEDULED_DATETIME',
  SCHEDULED_MODE = 'TASK__SCHEDULED_MODE',
  STATUS = 'TASK__STATUS',
  TASK_TYPE = 'TASK__TASK_TYPE',
  TITLE = 'TASK__TITLE',
  UPDATE_DATETIME = 'TASK__UPDATE_DATETIME',
  AUTOMATED_EMAIL_TO = 'TASK__AUTOMATED_EMAIL_TO',
  IS_AUTOMATED_EMAIL = 'TASK__IS_ACTION_AUTOMATED_EMAIL',
  IS_ACTION_CALL = 'TASK__IS_ACTION_CALL',
  IS_ACTION_EMAIL = 'TASK__IS_ACTION_EMAIL',
  IS_ACTION_LINKEDIN = 'TASK__IS_ACTION_LINKEDIN_MESSAGE',
  IS_ACTION_CUSTOM_TASK = 'TASK__IS_ACTION_CUSTOM_TASK',
  MEETING_ACTIVITY = 'TASK__ACTIVITY_MEETING',
  TEMPLATE = 'TASK__TEMPLATE_ID',
  SUGGESTED_PITCH = 'TASK__SUGGESTED_PITCH',
  SUGGESTED_LINKEDIN_TEMPLATE = 'TASK__SUGGESTED_LINKEDIN_TEMPLATE',
  SUGGESTED_WHATSAPP_TEMPLATE = 'TASK__SUGGESTED_WHATSAPP_TEMPLATE',
  CUSTOM_TASK = 'TASK__CUSTOM_TASK',
  TASK_ICON = 'TASK__ICON',
  CADENCE_STEP = 'TASK__CADENCE_STEP_ID',
  SKIPPABLE_REASONS = 'TASK__SKIPPABLE_REASONS',
  TASK_IS_SKIPPABLE = 'TASK__IS_SKIPPABLE',
  PRIORITY = 'TASK__PRIORITY',
  ACTION_TYPE = 'TASK__ACTION_TYPE',
  STEP_ID = 'TASK__CADENCE_STEP_ID',
  DATA_SOURCE = 'TASK__DATA_SOURCE',
  REMINDER = 'TASK__REMINDER',
  RELATED_OBJECT_ID = 'TASK__RELATED_OBJECT_ID',
}

export enum TASK_PRIORITY_VALUE {
  IMPORTANT = 'TASK__PRIORITY__IMPORTANT',
  NO_PRIORITY = 'TASK__PRIORITY__NO_PRIORITY',
}

export enum TASK_ACTION {
  CALL = 'TASK__IS_ACTION_CALL',
  EMAIL = 'TASK__IS_ACTION_EMAIL',
  LINKEDIN_MESSAGE = 'TASK__IS_ACTION_LINKEDIN_MESSAGE',
  AUTOMATED_EMAIL = 'TASK__IS_ACTION_AUTOMATED_EMAIL',
  CUSTOM_TASK = 'TASK__IS_ACTION_CUSTOM_TASK',
}

export enum TASK_ACTION_VALUE {
  CALL_YES = 'TASK__IS_ACTION_CALL__YES',
  CALL_NO = 'TASK__IS_ACTION_CALL__NO',
  EMAIL_YES = 'TASK__IS_ACTION_EMAIL__YES',
  EMAIL_NO = 'TASK__IS_ACTION_EMAIL__NO',
  LINKEDIN_MESSAGE_YES = 'TASK__IS_ACTION_LINKEDIN_MESSAGE__YES',
  LINKEDIN_MESSAGE_NO = 'TASK__IS_ACTION_LINKEDIN_MESSAGE__NO',
  AUTOMATED_EMAIL_YES = 'TASK__IS_ACTION_AUTOMATED_EMAIL__YES',
  AUTOMATED_EMAIL_NO = 'TASK__IS_ACTION_AUTOMATED_EMAIL__NO',
  CUSTOM_TASK_YES = 'TASK__IS_ACTION_CUSTOM_TASK__YES',
  CUSTOM_TASK_NO = 'TASK__IS_ACTION_CUSTOM_TASK__NO',
}

export enum TASK_AUTOMATED_VALUE {
  AUTOMATED_YES = 'TASK__IS_AUTOMATED__YES',
}

export enum TASK_SKIPPABLE_VALUE {
  YES = 'TASK__IS_SKIPPABLE__YES',
  NO = 'TASK__IS_SKIPPABLE__NO',
}

export enum TASK_STATUS_VALUE_LOGIC_ROLE {
  COMPLETED_OVERDUE = 'TASK__STATUS__COMPLETED_OVERDUE',
  COMPLETED = 'TASK__STATUS__COMPLETED',
  OVERDUE = 'TASK__STATUS__OVERDUE',
  REJECTED = 'TASK__STATUS__REJECTED',
  TODO = 'TASK__STATUS__TODO',
}

export enum TASK_TYPE {
  ADD_LEADS_TO_QC = 'ADD_LEADS_TO_QC',
  CONTACT_BEFORE_MEETING = 'CONTACT_BEFORE_MEETING',
  MEETING = 'MEETING',
  NEXT_STEP = 'NEXT_STEP',
  PROSPECT_CADENCE = 'PROSPECT_CADENCE',
  START_CADENCE = 'START_CADENCE',
  SCHEDULED_EMAIL = 'SCHEDULED_EMAIL',
}

export enum CUSTOM_TASK_LOGIC_ROLE {
  WHATSAPP = 'WHATSAPP',
  RECALL = 'RECALL',
  SALESFORCE_EMAIL = 'SALESFORCE_EMAIL',
  SALESFORCE_CUSTOM = 'SALESFORCE_CUSTOM',
  SALESFORCE_MEETING = 'SALESFORCE_MEETING',
  SALESFORCE_CALL = 'SALESFORCE_CALL',
  WHATSAPP_BUSINESS = 'WHATSAPP_BUSINESS',
}

export type TaskRelatedBobject<T extends MainBobjectTypes = MainBobjectTypes> = {
  id: BobjectId<T>['value'];
  name: string;
};

export interface TaskFeedTask {
  actionCall: boolean;
  actionEmail: boolean;
  actionLinkedin: boolean;
  cadenceId: string;
  canBeMarkedAsDone: boolean;
  color: ColorType;
  company?: TaskRelatedBobject<BobjectTypes.Company>;
  customTaskId: string;
  description?: string;
  extraFields: Array<ExtraField>;
  icon: IconType;
  id: BobjectId<BobjectTypes.Task>['value'];
  lead?: TaskRelatedBobject<BobjectTypes.Lead>;
  opportunity?: TaskRelatedBobject<BobjectTypes.Opportunity>;
  owner?: string;
  priority: string;
  relatedObjectId: string[];
  scheduledDatetime?: string;
  score?: number;
  skippable: boolean;
  title: string;
  type: TASK_TYPE;
  step?: string;
  timezone?: string;
  linkedinUrl?: string;
  author?: string;
  crmIds?: {
    crmId?: string;
    crmName?: string;
  };
}

interface ExtraField {
  id: string;
  name: string;
  icon: IconType;
  value: string;
}
