import { ACTIVITY_FIELDS_LOGIC_ROLE, ACTIVITY_TYPES_VALUES_LOGIC_ROLE } from '@bloobirds-it/types';
import { api, injectReferencesSearchProcess } from '@bloobirds-it/utils';
import useSWR from 'swr';

export const useEmailThreadActivities = (threadId: string, accountId: string) => {
  const fetchActivities = () => {
    return api.post('/bobjects/' + accountId + '/Activity/search', {
      //TODO: Add columns in query
      query: {
        [ACTIVITY_FIELDS_LOGIC_ROLE.TYPE]: [ACTIVITY_TYPES_VALUES_LOGIC_ROLE.EMAIL],
        [ACTIVITY_FIELDS_LOGIC_ROLE.EMAIL_THREAD_ID]: {
          query: threadId,
          searchMode: 'EXACT__SEARCH',
        },
      },
      page: 0,
      formFields: true,
      pageSize: 30,
      injectReferences: true,
      sort: [
        {
          field: ACTIVITY_FIELDS_LOGIC_ROLE.TIME,
          direction: 'DESC',
        },
      ],
      trimBigBodies: true,
    });
  };

  const { data } = useSWR(
    accountId && threadId && `/emailThreadActivities/${threadId}`,
    fetchActivities,
  );

  return {
    data,
    activities: data && injectReferencesSearchProcess(data?.data)?.contents,
    total: data?.data?.totalMatching,
  };
};
