import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTestRuns } from '@bloobirds-it/copilot';
import {
  Button,
  Modal,
  ModalHeader,
  ModalTitle,
  ModalCloseIcon,
  Text,
  ModalContent,
  ModalFooter,
  Icon,
  Spinner,
} from '@bloobirds-it/flamingo-ui';
import { useLoadingPromise } from '@bloobirds-it/hooks';
import { CopilotInsightVersion } from '@bloobirds-it/types';
import clsx from 'clsx';

import { useInsights } from '../../hooks/useInsights';
import { useSetsData } from '../../hooks/useSetsData';
import styles from '../../playgroundPages.module.css';

export const NewRunModal = ({
  selectedVersion,
  onClose,
}: {
  selectedVersion: CopilotInsightVersion;
  onClose: () => void;
}) => {
  const [selectedTestSet, setSelectedTestSet] = useState();
  const { createTestRun } = useTestRuns();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.newRunModal' });
  const { testSets } = useSetsData();
  const { insights } = useInsights();
  const currentInsight = insights.get(selectedVersion?.insightId);

  const { loading, promiseHandler } = useLoadingPromise();

  async function onCreateTestRun() {
    promiseHandler(
      async () =>
        createTestRun({
          testSetId: selectedTestSet?.pk,
          insightId: selectedVersion.id,
        }),
      {
        errorToast: 'ai.newRunModal.error',
        successToast: 'ai.newRunModal.success',
        onFinally: onClose,
      },
    );
  }

  // Only show test sets that have the same activity type as the insight
  const filteredTestSets = testSets.filter(
    testSet =>
      testSet.activity_type === currentInsight?.activityType ||
      currentInsight?.activityType === 'All',
  );

  return (
    <Modal open onClose={onClose} width={370}>
      <ModalHeader color="white" size="small">
        <ModalTitle size="small" icon="test" color="purple">
          <Text weight="bold">{t('title')}</Text>
        </ModalTitle>
        <ModalCloseIcon color="black" size="small" onClick={onClose} />
      </ModalHeader>
      <ModalContent className={styles.insight_createTestRun}>
        {filteredTestSets.map(testSet => {
          const { pk, name, activity_type, activities } = testSet;

          const classNamesTestSet = clsx(styles.testSets_testSet, {
            [styles.active]: selectedTestSet?.pk === pk,
          });

          return (
            <div key={pk} className={classNamesTestSet} onClick={() => setSelectedTestSet(testSet)}>
              <Text className={styles.testSets_testSetName} size="s" color="peanut" weight="heavy">
                {name}
              </Text>
              <div className={styles.testSets_testSetInfo}>
                {activity_type === 'Call' ? (
                  <Icon name="phone" size={16} color="melon" />
                ) : (
                  <Icon name="calendar" size={16} color="tomato" />
                )}
                <Text size="xs" color="peanut">
                  {Object.keys(activities).length || 0}{' '}
                  {activity_type === 'Call' ? 'Calls' : 'Meetings'}
                </Text>
              </div>
            </div>
          );
        })}
      </ModalContent>
      <ModalFooter className={styles.insight_createTestRunFooter}>
        <Button
          uppercase={false}
          size="small"
          color="purple"
          onClick={onCreateTestRun}
          disabled={!selectedTestSet && loading}
        >
          {loading && <Spinner name={'loadingCircle'} />}
          {!loading && t('createButton')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
