import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Callout,
  Dropdown,
  Icon,
  Spinner,
  Text,
  useVisible,
} from '@bloobirds-it/flamingo-ui';
import { CopilotInsight, CopilotInsightVersion } from '@bloobirds-it/types';
import { motion } from 'framer-motion';

import styles from '../../../playgroundPages.module.css';
import { SetAsActiveButton } from '../../setAsActiveButton/setAsActiveButton';

interface IPrompTabProps {
  onSave: (copilotInsight: CopilotInsightVersion, createTestRunToo: boolean) => any;
  selectedInsight: CopilotInsight;
  selectedVersion: CopilotInsightVersion;
}

export function PromptTab({ selectedVersion, onSave }: IPrompTabProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground' });
  const [editMode, setEditMode] = useState(false);
  const [submitting, setSubmitting] = useState<'VERSION' | 'VERNTEST' | null>(null);
  const [editedPrompt, setEditedPrompt] = useState('');
  const { visible, setVisible, ref } = useVisible();
  const handleEditClick = () => {
    setEditMode(true);
    setEditedPrompt(selectedVersion?.prompt);
  };

  const handleSaveClick = async (saveAndCreateTestRun: boolean) => {
    // Save the edited prompt and create a new test run here
    // After saving, exit edit mode
    try {
      setSubmitting(saveAndCreateTestRun ? 'VERNTEST' : 'VERSION');

      await onSave?.({ ...selectedVersion, prompt: editedPrompt }, saveAndCreateTestRun);
      setVisible(false);
      setSubmitting(null);
      setEditMode(false);
    } catch (error) {
      setSubmitting(null);
      console.error('handleSaveClick:', error);
    }
  };

  const handleDiscard = () => {
    setEditMode(false);
    setEditedPrompt('');
  };
  return (
    <div className={styles.testRun_prompt}>
      <motion.div
        style={{ overflow: 'hidden', height: '100%' }}
        initial={{ width: '100%' }}
        animate={{ width: editMode ? '50%' : '100%' }}
        transition={{ duration: 0.5 }}
      >
        <div className={styles.testRun_prompt_text_input}>
          <div className={styles.testRun_prompt_header}>
            <div className={styles.testRun_prompt_row_header_text}>
              <Text size="m" color="softPeanut" weight="bold">
                Prompt
              </Text>
              <Text size="m" color="softPeanut">
                {`${t('version')}: ${selectedVersion?.version}`}
              </Text>
              <div style={{ paddingLeft: '16px' }}>
                <SetAsActiveButton variant="clear" version={selectedVersion} />
              </div>
            </div>
            {!editMode && (
              <Button
                size="small"
                color="peanut"
                variant="secondary"
                iconLeft="edit"
                onClick={handleEditClick}
              >
                {t('promptTab.editPrompt')}
              </Button>
            )}
          </div>
          <div className={styles.testRun_prompt_text}>{selectedVersion?.prompt}</div>
        </div>
      </motion.div>
      <motion.div
        style={{ overflow: 'hidden', height: '100%' }}
        initial={{ width: '0%', opacity: 0 }}
        animate={{ width: editMode ? '50%' : '0%', opacity: editMode ? 1 : 0 }}
        transition={{ duration: 0.5 }}
      >
        <div className={styles.testRun_prompt_text_input}>
          <div>
            <div className={styles.testRun_prompt_row_header_buttons}>
              <Button
                size="small"
                color="bloobirds"
                variant="clear"
                onClick={handleDiscard}
                iconLeft="trashFull"
              >
                {t('promptTab.discard')}
              </Button>
              <Dropdown
                width="100%"
                visible={visible}
                ref={ref}
                zIndex={102}
                arrow={false}
                anchor={
                  <Button
                    size="small"
                    color={selectedVersion?.prompt === editedPrompt ? 'gray' : 'bloobirds'}
                    variant="secondary"
                    disabled={selectedVersion?.prompt === editedPrompt}
                    onClick={() => selectedVersion?.prompt !== editedPrompt && setVisible(true)}
                    iconLeft="save"
                  >
                    {t('promptTab.save')}
                  </Button>
                }
                style={{ minWidth: 0, minHeight: 0 }}
              >
                <div className={styles.saveDropdownContainer}>
                  <Callout variant="neutral" width="100%" icon="infoFilled">
                    <Text size="m">{t('promptTab.saveDropdown.callout')}</Text>
                  </Callout>
                  <Text size="m" weight="bold">
                    {t('promptTab.saveDropdown.question')}
                  </Text>
                  <Text size="m" color="softPeanut">
                    {t('promptTab.saveDropdown.description')}
                  </Text>
                </div>
                <div className={styles.saveButtonsContainer}>
                  <Button
                    size="small"
                    color="bloobirds"
                    variant="clear"
                    disabled={submitting !== null}
                    onClick={() => handleSaveClick(false)}
                    className={styles.saveButtons}
                  >
                    {submitting === 'VERSION' && <Spinner name="loadingCircle" size={12} />}
                    {t('promptTab.saveDropdown.justVersion')}
                  </Button>
                  <Button
                    size="small"
                    color="bloobirds"
                    variant="primary"
                    disabled={submitting !== null}
                    onClick={() => handleSaveClick(true)}
                    className={styles.saveButtons}
                  >
                    {submitting === 'VERNTEST' && (
                      <Spinner name="loadingCircle" size={12} color="white" />
                    )}
                    {t('promptTab.saveDropdown.versionAndTestRun')}
                  </Button>
                </div>
              </Dropdown>
            </div>
            <div className={styles.testRun_prompt_row_header}>
              <div className={styles.testRun_prompt_row_header_text}>
                <Icon name="plus" color="softPeanut" size={16} />
                <Text size="m" color="softPeanut" weight="bold">
                  {t('promptTab.newPrompt')}
                </Text>
                <Text size="m" color="softPeanut">
                  {`${t('version')}: ${(selectedVersion?.version || 0) + 1}`}
                </Text>
              </div>
            </div>
          </div>
          <textarea
            value={editedPrompt}
            className={styles.testRun_prompt_textArea}
            onChange={e => setEditedPrompt(e.target.value)}
          />
        </div>
      </motion.div>
    </div>
  );
}
