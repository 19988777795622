import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  Button,
  Input,
  Modal,
  ModalCloseIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  Spinner,
  Callout,
  Text,
} from '@bloobirds-it/flamingo-ui';
import { useLoadingPromise } from '@bloobirds-it/hooks';
import { api } from '@bloobirds-it/utils';

import useSendToPlaygroundModal from '../../hooks/useSendToPlaygroundModal';
import styles from './sendToPlaygroundModal.module.css';

const NoValidActivities = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.sendToPlaygroundModal.noValidActivities',
  });

  return (
    <>
      {(t('messages', { returnObjects: true }) as string[]).map(
        (message: string, index: number) => (
          <Text align="center" size="m" key={index}>
            <Trans i18nKey={`ai.sendToPlaygroundModal.noValidActivities.messages.${index}`}>
              {message}
            </Trans>
          </Text>
        ),
      )}
    </>
  );
};

const PartialSet = ({
  validActivitiesCount,
  invalidActivities,
  type,
}: {
  validActivitiesCount: number;
  invalidActivities: number;
  type: string;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.sendToPlaygroundModal.partialSet' });

  return (
    <>
      <Text size="m">
        <Trans i18nKey="ai.sendToPlaygroundModal.partialSet.title" />
      </Text>
      <br />
      {type === 'Email' && (
        <Callout width="100%" withoutIcon>
          <Trans i18nKey={`ai.sendToPlaygroundModal.partialSet.emailWarning`} />
        </Callout>
      )}
      <br />
      <Text size="m">
        <Trans
          i18nKey={'ai.sendToPlaygroundModal.partialSet.validActivities'}
          values={{ type }}
          count={validActivitiesCount}
        />
      </Text>
      <Text size="m">
        <Trans
          i18nKey={'ai.sendToPlaygroundModal.partialSet.invalidActivities'}
          values={{ count: invalidActivities }}
        />
      </Text>
      <br />
      <Text size="m">{t('disclaimer')}</Text>
      <Text size="m">{t('doYouWishToContinue')}</Text>
    </>
  );
};

const CorrectSet = ({
  validActivitiesCount,
  type,
}: {
  validActivitiesCount: number;
  type: string;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.sendToPlaygroundModal.correctSet' });

  return (
    <>
      <Text size="m">{t('title')}</Text>
      <br />
      {type === 'Email' && (
        <Callout width="100%" withoutIcon>
          <Trans i18nKey={`ai.sendToPlaygroundModal.partialSet.emailWarning`} />
        </Callout>
      )}
      <Text size="m">
        <Trans
          i18nKey={'ai.sendToPlaygroundModal.correctSet.validActivities'}
          count={validActivitiesCount}
          values={{ type }}
        />
      </Text>
      <br />
      <Text size="m">{t('doYouWishToContinue')}</Text>
    </>
  );
};

const TypeMismatch = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'ai.sendToPlaygroundModal.typeMismatch',
  });

  return (
    <>
      <Text size="m">
        <Trans i18nKey="ai.sendToPlaygroundModal.typeMismatch.activityTypes" />
      </Text>
      <Text size="m">{t('instruction')}:</Text>
      <br />
      <Text size="m">{t('calls')}</Text>
      <Text size="m">{t('meetings')}</Text>
      <Text size="m">{t('emails')}</Text>
    </>
  );
};

const CheckingActivities = () => {
  return (
    <div className={styles.checking_activities}>
      <Spinner name="loadingCircle" size={48} />
    </div>
  );
};

const SendToPlaygroundModal = () => {
  const {
    isOpen,
    closeSendToPlaygroundModal,
    bobjects,
    typeMismatch,
    checkingActivities,
    checkedActivities,
    validActivitiesCount,
    type,
  } = useSendToPlaygroundModal();

  const { t: baseT } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.sendToPlaygroundModal' });

  const [testSetName, setTestSetName] = useState(undefined);

  const canSend = !checkingActivities && !typeMismatch && validActivitiesCount > 0;

  const onClose = () => {
    closeSendToPlaygroundModal();
    setTestSetName(undefined);
  };

  const { loading, promiseHandler } = useLoadingPromise();

  const handleConfirm = () => {
    promiseHandler(
      () =>
        api.post('/copilot/playground/test-set', {
          activities: bobjects.map(b => b.id.objectId),
          activityType: type,
          name: testSetName,
        }),
      {
        onSuccess: onClose,
        successToast: 'ai.sendToPlaygroundModal.successToast',
        errorToast: 'ai.sendToPlaygroundModal.errorToast',
      },
    );
  };

  return (
    <Modal width={500} open={isOpen} onClose={onClose}>
      <ModalHeader size="small">
        <ModalTitle icon="playground" size="small">
          {t('title')}
        </ModalTitle>
        <ModalCloseIcon size="small" onClick={onClose} />
      </ModalHeader>
      <ModalContent className={styles.content}>
        {checkingActivities && <CheckingActivities />}
        {!checkingActivities && typeMismatch && <TypeMismatch />}
        {!checkingActivities &&
          !typeMismatch &&
          checkedActivities &&
          validActivitiesCount === 0 && <NoValidActivities />}
        {canSend && validActivitiesCount === bobjects.length && (
          <CorrectSet validActivitiesCount={validActivitiesCount} type={type} />
        )}
        {canSend && validActivitiesCount < bobjects.length && (
          <PartialSet
            type={type}
            validActivitiesCount={validActivitiesCount}
            invalidActivities={
              checkedActivities.invalidActivities || bobjects.length - validActivitiesCount
            }
          />
        )}
        {canSend && (
          <Input
            className={styles.name_input}
            placeholder="Test set name"
            value={testSetName}
            onChange={setTestSetName}
            width="100%"
          />
        )}
      </ModalContent>
      <ModalFooter>
        <Button variant="clear" onClick={onClose}>
          {baseT(canSend ? 'common.cancel' : 'common.close')}
        </Button>
        {canSend && (
          <Button disabled={!testSetName || loading} onClick={handleConfirm}>
            {loading && <Spinner name={'loadingCircle'} />}
            {!loading && baseT('common.confirm')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default SendToPlaygroundModal;
