export const isElementLoaded = async selector => {
  let counter = 0;
  while (document.querySelector(selector) === null && counter <= 200) {
    await new Promise(resolve => {
      requestAnimationFrame(resolve);
    });
    counter = counter + 1;
  }
  return document.querySelector(selector);
};

type Options = {
  childList: boolean; // Observar cambios en los hijos directos del nodo
  subtree: boolean; // Observar cambios en todos los nodos descendientes del subárbol (WARNING: puede ser muy costoso)
  attributes: boolean; // Observar cambios en los atributos del nodo
  characterData: boolean; // Observar cambios en el contenido de texto (nodos de texto)
};

//TODO we should (most of the time) exclude the DOM mutations caused by the extension itself
export const waitForElement = (
  querySelector,
  resolve,
  reject,
  timeout = 4000,
  options: Options = { childList: true, subtree: true, attributes: false, characterData: false },
) => {
  return new Promise(() => {
    const observer = new MutationObserver((mutationsList, observer) => {
      if (!mutationsList.some(mutation => mutation.addedNodes.length > 0)) return;
      mutationsList.forEach(mutation => {
        // @ts-ignore
        if (mutation.addedNodes.length > 0 && !mutation.target?.id === 'bb-root') {
          const element = document.querySelector(querySelector);
          if (element) {
            observer.disconnect();
            resolve(element);
          }
        }
      });

      if (document.querySelector(querySelector)) {
        observer.disconnect();
        resolve(document.querySelector(querySelector));
      }
    });

    // Start observing the document for changes.
    observer.observe(document.body, options);

    // Optional timeout to reject the promise if the element isn't found after a certain time
    setTimeout(() => {
      observer.disconnect();
      reject(new Error('Element ' + querySelector + ' not found within timeout'));
    }, timeout); // Adjust this timeout as needed
  });
};
