import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ColorType,
  IconButton,
  Item,
  Select,
  Switch,
  Text,
  Tooltip,
} from '@bloobirds-it/flamingo-ui';
import { relatedPickableIcons } from '@bloobirds-it/utils';
import clsx from 'clsx';

import {
  EntityCard,
  EntityCardItem,
} from '../../../../components/entityList/entityCard/entityCard';
import { IconPicker, PickableIcon } from '../../../../components/iconPicker/iconPicker';
import { RelatedObjectsTableProps, useCreateRelatedObjects } from '../hooks/useCreateRelatedObject';
import { MainObjectConfirmDeleteModal } from './mainObjectConfirmDeleteModal';
import styles from './mainObjects.module.css';
import { MainBobjectSkeleton } from './mainObjectsTable';
import { ManageMainObjectModal } from './manageMainObjectModal';

export const MainObjectCard = ({
  data: relatedObject,
  displayedCount,
  setDisplayedCount,
  disabled = false,
}: {
  data: RelatedObjectsTableProps;
  displayedCount: number;
  setDisplayedCount: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
}) => {
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    ...relatedObject,
    objectTypeFieldRelated:
      relatedObject?.objectTypeFieldRelated ??
      (relatedObject?.availableObjectTypeFieldRelated?.length === 1
        ? [relatedObject?.availableObjectTypeFieldRelated[0]]
        : []),
    availableObjectTypeFieldRelated: [
      ...(relatedObject?.availableObjectTypeFieldRelated ?? []),
      ...(relatedObject?.objectTypeFieldRelated ?? []),
    ],
    availableFields: relatedObject?.availableFields ?? [],
    selectedFields: relatedObject?.selectedFields ?? [],
    display: relatedObject.display ?? false,
    icon: relatedObject.icon ?? 'salesforce',
  });
  const [openSelectFieldsModal, setOpenSelectFieldsModal] = useState(false);
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
  const { handleUpdate } = useCreateRelatedObjects();

  const { t } = useTranslation('translation', {
    keyPrefix: 'accountSettings.relatedObjects',
  });

  const {
    id,
    icon,
    objectApiName,
    title,
    availableFields,
    display,
    statusField,
    systemObject,
    objectType,
  } = data;

  const updateData = async (newData: RelatedObjectsTableProps) => {
    setLoading(true);
    const { errors, data } = await handleUpdate(newData);
    setDisplayedCount(count => count + (newData?.display ? 1 : -1));
    setData(data);
    setErrors(errors);
    setLoading(false);
  };

  const checkIfDisplayEditable = () => {
    if (systemObject) {
      return false;
    }

    return displayedCount >= 5 ? display : true;
  };

  const isDisplayAbleEditable = checkIfDisplayEditable();
  const isEditable = !systemObject && isDisplayAbleEditable;
  const tooltipText = !isEditable
    ? !isDisplayAbleEditable
      ? t('tooltipMaximumObjectsReached')
      : t('tooltipRequiredFields')
    : null;

  if (loading) {
    return <MainBobjectSkeleton id={id} />;
  }

  const cardClasses = clsx(styles.cardRow, {
    [styles.cardRowSistem]: systemObject,
  });

  return (
    <>
      <EntityCard key={`field-${data?.id ?? data?.objectApiName?.apiName}`} className={cardClasses}>
        <EntityCardItem
          size="small"
          align="center"
          className={styles.cardItem}
          style={{ width: '10%' }}
        >
          <IconPicker
            clickable={isEditable}
            disabled={disabled}
            borderless={systemObject}
            selectedIcon={{
              name: icon || 'salesforce',
              color: relatedPickableIcons.find(p => p.name === icon ?? 'salesforce')
                ?.color as ColorType,
            }}
            onSelectIcon={pick => {
              updateData({
                ...data,
                icon: pick.name,
              });
            }}
            hasError={false /* error.icon */}
            pickableIcons={relatedPickableIcons as PickableIcon[]}
          />
        </EntityCardItem>

        {systemObject ? (
          <>
            <EntityCardItem size="small" style={{ width: '15%' }}>
              <Tooltip title={title} position="top">
                <Text size="s" ellipsis={20}>
                  {title}
                </Text>
              </Tooltip>
            </EntityCardItem>
            <EntityCardItem className={styles.systemObject} style={{ width: '60%' }}>
              <Text size="xs" color="softPeanut">
                {t('systemObject')}
              </Text>
            </EntityCardItem>
            <EntityCardItem
              size="small"
              align="center"
              className={styles.buttons}
              style={{ width: '15%' }}
            >
              <span className={styles.display}>
                <Tooltip title={t('tooltipDisplayMainObject')} position="top">
                  <Switch
                    disabled={!isEditable}
                    checked={display}
                    onChange={() => {
                      updateData({
                        ...data,
                        display: !display,
                      });
                    }}
                  />
                </Tooltip>
              </span>
            </EntityCardItem>
          </>
        ) : (
          <>
            <EntityCardItem size="small" style={{ width: '15%' }}>
              <Text size="s" ellipsis={20}>
                {objectApiName?.label}
              </Text>
            </EntityCardItem>
            <EntityCardItem style={{ width: '20%' }}>
              <Text size="s" ellipsis={20}>
                {objectType}
              </Text>
            </EntityCardItem>

            <EntityCardItem
              error={errors?.errorTitleField && t('fieldRequired')}
              style={{ width: '20%' }}
              className={styles.select}
            >
              <Select
                autocomplete
                size="small"
                width="100%"
                placeholder={t('titleSelectPlaceholder')}
                value={title}
                onChange={value => {
                  updateData({
                    ...data,
                    title: value,
                  });
                }}
                disabled={disabled}
              >
                {availableFields.map(field => (
                  <Item key={field.apiName} label={field.label} value={field.apiName}>
                    {field.label}
                  </Item>
                ))}
              </Select>
            </EntityCardItem>
            <EntityCardItem
              error={errors?.errorObjectTypeFieldRelated && t('fieldRequired')}
              style={{ width: '20%' }}
              className={styles.select}
            >
              <Select
                autocomplete
                size="small"
                width="100%"
                placeholder={t('statusFieldTitlePlaceholder')}
                value={statusField}
                onChange={value => {
                  updateData({
                    ...data,
                    statusField: value,
                  });
                }}
                disabled={disabled}
              >
                {availableFields?.map(field => (
                  <Item key={field.apiName} label={field.label} value={field.apiName}>
                    {field.label}
                  </Item>
                ))}
              </Select>
            </EntityCardItem>
            <EntityCardItem
              size="small"
              align="center"
              className={styles.buttons}
              style={{ width: '15%' }}
            >
              <span className={styles.display}>
                <Tooltip title={tooltipText} position="top">
                  <Switch
                    disabled={!isDisplayAbleEditable || disabled}
                    checked={display}
                    onChange={() => {
                      updateData({
                        ...data,
                        display: !display,
                      });
                    }}
                  />
                </Tooltip>
                <Tooltip title={t('tooltipEditValues')} position="top">
                  <IconButton
                    name={'edit'}
                    color="bloobirds"
                    size={24}
                    disabled={!isEditable || disabled}
                    onClick={() => setOpenSelectFieldsModal(true)}
                  />
                </Tooltip>
                <Tooltip title={t('tooltipDelete')} position="top">
                  <IconButton
                    disabled={!isEditable || disabled}
                    name={'trashFull'}
                    color="bloobirds"
                    size={24}
                    onClick={() => setOpenConfirmDeleteModal(true)}
                  />
                </Tooltip>
              </span>
            </EntityCardItem>
          </>
        )}
      </EntityCard>
      {openSelectFieldsModal && (
        <ManageMainObjectModal
          object={data}
          onClose={() => {
            setOpenSelectFieldsModal(false);
          }}
          updateData={updateData}
        />
      )}
      {openConfirmDeleteModal && (
        <MainObjectConfirmDeleteModal id={id} setOpen={setOpenConfirmDeleteModal} />
      )}
    </>
  );
};
