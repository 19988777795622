import { IconType } from '@bloobirds-it/flamingo-ui';
import { RelatedObjectsTableProps, RelatedBobjectApiId } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import useSWR from 'swr';

const parseRelatedObjects = (response: RelatedObjectsTableProps[]) => {
  return {
    data: response,
    relatedObjects: response.filter(
      (relatedObject: RelatedObjectsTableProps) => !relatedObject.systemObject,
    ) as RelatedObjectsTableProps<false>[],
  };
};
export const useGetMainObjects = (): {
  data: RelatedObjectsTableProps[];
  relatedObjects: RelatedObjectsTableProps<false>[];
  isLoading: boolean;
  isError: boolean;
  mutate: any;
  getIconByRelatedType: (relatedObjectId: RelatedBobjectApiId) => IconType | null;
} => {
  const { data, error, isLoading, mutate } = useSWR(
    `/utils/service/salesforce/relatedMainObjects`,
    (url: string) =>
      api.get(url).then(data => {
        return parseRelatedObjects(data?.data);
      }),
  );

  const getIconByRelatedType = (relatedObjectId: RelatedBobjectApiId) => {
    return data?.relatedObjects?.find(related =>
      relatedObjectId?.includes(`${related.objectType}/${related.objectApiName.apiName}`),
    )?.icon;
  };

  return {
    ...data,
    isLoading,
    isError: error,
    mutate,
    getIconByRelatedType,
  };
};
