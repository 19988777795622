import React from 'react';
import { useController, Control } from 'react-hook-form';

import clsx from 'classnames';

import styles from './nativeNumberField.module.css';

interface NumberFieldProps {
  control: Control<any>;
  required?: boolean;
  name: string;
  id: string;
  size?: 'small' | 'medium';
  className?: string;
  width?: string;
  requiredMessage?: string;
  formKey?: string;
  min?: number;
  max?: number;
  step?: number;
  placeholder?: string;
}

export const NativeNumberField = ({
  control,
  required,
  name,
  id,
  size = 'medium',
  className,
  width,
  requiredMessage,
  formKey,
  min,
  max,
  step,
  placeholder,
}: NumberFieldProps) => {
  const {
    field: { value, onChange, onBlur },
    fieldState: { error },
  } = useController({
    control,
    name: formKey ?? `fields.${id}`,
    rules: {
      required: {
        value: required,
        message: requiredMessage,
      },
      validate: value =>
        value === undefined ||
        value === '' ||
        ((!min || value >= min) && (!max || value <= max)) ||
        `Value must be between ${min} and ${max}`,
    },
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.valueAsNumber);
  };

  return (
    <div className={styles.numberFieldWrapper}>
      <input
        type="number"
        id={id}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        className={clsx(styles.numberInput, {
          [className]: className,
          [styles.error]: error,
          [styles.small]: size === 'small',
        })}
        style={{ width }}
        placeholder={placeholder || (size === 'small' ? undefined : name)}
        min={min}
        max={max}
        step={step}
      />
      {error && <p className={styles.errorMessage}>{error.message}</p>}
    </div>
  );
};

interface NativeNumberInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'size'> {
  size?: 'small' | 'medium';
  className?: string;
  error: boolean;
}

export const NumberInput = ({
  className = '',
  error = false,
  size = 'small',
  ...props
}: NativeNumberInputProps) => {
  return (
    <input
      {...props}
      type="number"
      className={clsx(styles.numberInput, {
        [className]: className,
        [styles.error]: error,
        [styles.small]: size === 'small',
      })}
    />
  );
};
