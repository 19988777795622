import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Text } from '@bloobirds-it/flamingo-ui';
import { CopilotInsight, CopilotInsightVersion } from '@bloobirds-it/types';

import { useInsightVersions } from '../../hooks/useInsightVersions';
import styles from '../../playgroundPages.module.css';
import { InsightHeader } from './insightHeader';
import { InsightTabs } from './insightTabs';

const InsightsInfo = ({
  selectedInsight,
  setSelectedInsight,
}: {
  selectedInsight: CopilotInsight;
  setSelectedInsight: (insight: CopilotInsight) => void;
}) => {
  const versionId = selectedInsight?.id;
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightList' });

  const { isLoading, versions } = useInsightVersions(versionId);

  // order by version from newest to oldest
  const history: CopilotInsightVersion[] = [...versions.values()].sort(
    (a: any, b: any) => b.version - a.version,
  );

  if (!selectedInsight) {
    return (
      <div className={styles.insight_empty}>
        <span>👈</span>
        <Text size="s" color="peanut" align="center">
          <Trans t={t} i18nKey="empty" />
        </Text>
        <Text size="xs" color="peanut" align="center">
          <Trans t={t} i18nKey="emptyDesc" />
        </Text>
      </div>
    );
  }

  return (
    <div className={styles.insight_container}>
      <InsightHeader
        history={history}
        selectedInsight={selectedInsight}
        setSelectedInsight={setSelectedInsight}
      />
      <InsightTabs history={history} isLoading={isLoading} />
    </div>
  );
};

export default InsightsInfo;
