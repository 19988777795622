import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Label, Spinner, useToasts } from '@bloobirds-it/flamingo-ui';
import { CopilotInsightVersion } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import styles from '../../playgroundPages.module.css';

export const SetAsActiveButton = ({
  version,
  variant = 'secondary',
}: {
  version: CopilotInsightVersion;
  variant?: 'secondary' | 'clear';
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightCard' });

  const { createToast } = useToasts();
  const [isLoadingActive, setIsLoadingActive] = useState(false);

  const onSetAsActive = (id: string) => {
    setIsLoadingActive(true);
    api
      .get('/utils/service/copilot-insights/version/' + id + '/activate')
      .then(() => {
        mutate(`/api/insight/version/${version?.insightId}`);
        createToast({
          type: 'success',
          message: t('setActiveSuccess'),
        });
      })
      .catch(() => {
        createToast({
          type: 'error',
          message: t('setActiveError'),
        });
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoadingActive(false);
        }, 200);
      });
  };

  return version?.isActiveVersion ? (
    <Label
      key={'active'}
      size="small"
      color="lightBloobirds"
      textColor="bloobirds"
      icon="pin"
      iconColor="bloobirds"
      iconSize={14}
      uppercase={false}
    >
      {t('activeVersion')}
    </Label>
  ) : (
    <Button
      uppercase={false}
      size="small"
      color="purple"
      variant={variant}
      iconLeft={!isLoadingActive ? 'pinOutline' : undefined}
      onClick={() => onSetAsActive(version?.id)}
    >
      {isLoadingActive ? (
        <div className={styles.loading}>
          <Spinner name={'loadingCircle'} size={10} />
        </div>
      ) : (
        t('setActive')
      )}
    </Button>
  );
};
