import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon, IconButton, Label, Text, useHover } from '@bloobirds-it/flamingo-ui';
import { CopilotInsight, INSIGHT_TYPE_OPTIONS, TestRun, TestRunStatus } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import clsx from 'clsx';
import spacetime from 'spacetime';

import styles from '../../playgroundPages.module.css';
import { getProgressPercentage } from '../../utils';
import Results from './results';
import ResultsCard from './resultsCard';

const TestRunsCard = ({
  testRun,
  insights,
  isSelected,
  setSelectedRun,
  mutateTestRuns,
  refreshTestRuns,
}: {
  testRun: TestRun;
  insights: Map<string, CopilotInsight>;
  isSelected: boolean;
  setSelectedRun: () => void;
  mutateTestRuns: () => void;
  refreshTestRuns: () => void;
}) => {
  const { t: tBase } = useTranslation();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.runs' });
  const classes = clsx(styles.testRuns_testRun, {
    [styles.active]: isSelected,
  });
  const insight = insights.get(testRun.insight_id);
  async function handleStartTest() {
    try {
      await api.post(`/copilot/playground/test-run/${testRun.pk}/start`);
      mutateTestRuns();
      refreshTestRuns();
    } catch (error) {
      console.log('error', error);
    }
  }

  const insightType = INSIGHT_TYPE_OPTIONS(tBase).find(
    option => option.id === insight?.insightType,
  );

  return (
    <div key={testRun.pk} className={classes} onClick={setSelectedRun}>
      <div className={styles.testRuns_testRun_header}>
        <Text size="xs" weight="heavy">
          {insight?.title}
        </Text>
        {testRun.status === TestRunStatus.RUNNING && (
          <IconButton name="refresh" onClick={refreshTestRuns} />
        )}
      </div>

      <div className={styles.testRuns_testRun_insightInfo}>
        <Label size="small" color="verySoftPeanut">
          <Text size="xs" color="peanut">
            V.{testRun.version_number || 0}
          </Text>
        </Label>
        <Label size="small" color="verySoftPurple" uppercase={false}>
          <div className={styles.testRuns_testRun_insightType}>
            <Icon name="suggestions" size={14} color="purple" />
            <Text size="xs" color="purple">
              {insightType?.name}
            </Text>
          </div>
        </Label>
      </div>

      <div className={styles.testRuns_testRun_run_date}>
        {testRun.status !== TestRunStatus.NOT_STARTED ? 'Run' : 'Created'}{' '}
        {spacetime(testRun.created_at).format('nice')}
      </div>

      {testRun.status === TestRunStatus.NOT_STARTED && (
        <Button
          expand
          className={styles.testRuns_createRun}
          uppercase={false}
          size="small"
          color="peanut"
          variant="secondary"
          iconLeft="play"
          onClick={handleStartTest}
        >
          {t('startRun')}
        </Button>
      )}
      {testRun.status === TestRunStatus.RUNNING && (
        <div className={styles.progressBackground}>
          <div
            style={{ width: getProgressPercentage(testRun) }}
            className={styles.progressForeground}
          ></div>
          <p className={styles.progress}>{getProgressPercentage(testRun)}</p>
        </div>
      )}

      {testRun.status === TestRunStatus.COMPLETED && <Results testRun={testRun} />}
    </div>
  );
};

const TestRunsCardWrapper = ({
  testRun,
  insights,
  isSelected,
  setSelectedRun,
  mutateTestRuns,
  refreshTestRuns,
}: {
  testRun: TestRun;
  insights: Map<string, CopilotInsight>;
  isSelected: boolean;
  setSelectedRun: () => void;
  mutateTestRuns: () => void;
  refreshTestRuns: () => void;
}) => {
  const [hoverRef, isHovered] = useHover();

  return (
    <ResultsCard
      key={testRun.pk}
      testRun={testRun}
      isVisible={isHovered}
      insights={[...insights.values()]}
    >
      <div ref={hoverRef}>
        <TestRunsCard
          testRun={testRun}
          insights={insights}
          isSelected={isSelected}
          setSelectedRun={setSelectedRun}
          mutateTestRuns={mutateTestRuns}
          refreshTestRuns={refreshTestRuns}
        />
      </div>
    </ResultsCard>
  );
};

export default TestRunsCardWrapper;
