import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Skeleton, Text, useToasts } from '@bloobirds-it/flamingo-ui';
import { CopilotInsightVersion } from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import { mutate } from 'swr';

import styles from '../../playgroundPages.module.css';
import { SetAsActiveButton } from '../setAsActiveButton/setAsActiveButton';
import { InsightCardHistory } from './insightCardHistory';
import { NewRunModal } from './newRunModal';

const InsightHistory = ({
  history,
  isLoading,
}: {
  history: CopilotInsightVersion[];
  isLoading: boolean;
}) => {
  const [selectedVersion, setSelectedVersion] = useState<CopilotInsightVersion | null>(null);
  const [createNewTestRun, setCreateNewTestRun] = useState(false);
  const { createToast } = useToasts();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.insightCard' });
  const [isLoadingActive, setIsLoadingActive] = useState(false);
  useEffect(() => {
    if (history) {
      const activeVersion = history.find(version => version.isActiveVersion);
      if (!selectedVersion) {
        setSelectedVersion(activeVersion);
      }
    }
  }, [history]);

  const onSetAsActive = (id: string) => {
    setIsLoadingActive(true);
    api
      .get('/utils/service/copilot-insights/version/' + id + '/activate')
      .then(() => {
        mutate(`/api/insight/version/${selectedVersion.insightId}`);
        createToast({
          type: 'success',
          message: t('setActiveSuccess'),
        });
      })
      .catch(() => {
        createToast({
          type: 'error',
          message: t('setActiveError'),
        });
      })
      .finally(() => {
        setTimeout(() => {
          setIsLoadingActive(false);
        }, 200);
      });
  };

  return (
    <>
      <div className={styles.history}>
        <div className={styles.version}>
          {isLoading &&
            [...Array(3)].map((_, index) => (
              <Skeleton key={index} variant="rect" height={50} width="100%" />
            ))}
          {history?.map(version => {
            const isSelected = selectedVersion?.id === version.id;

            return (
              <InsightCardHistory
                key={version.id}
                version={version}
                isSelected={isSelected}
                setSelectedVersion={setSelectedVersion}
                onSetAsActive={onSetAsActive}
                isLoading={isLoadingActive}
              />
            );
          })}
        </div>
        <div className={styles.version_prompt}>
          {(isLoading || !selectedVersion) && (
            <div className={styles.insight_empty}>
              <span>👈</span>
              <Text size="s" color="peanut" align="center">
                <Trans t={t} i18nKey="empty" />
              </Text>
              <Text size="xs" color="peanut" align="center">
                <Trans t={t} i18nKey="emptyDesc" />
              </Text>
            </div>
          )}
          {selectedVersion && (
            <>
              <div className={styles.version_icons}>
                <div className={styles.version_icons}>
                  <SetAsActiveButton version={selectedVersion} variant="clear" />
                  <div
                    style={{ display: 'flex', gap: '4px', alignItems: 'center', fontSize: '14px' }}
                  >
                    <Text color="peanut" weight="heavy" size="s">
                      Temperature:
                    </Text>
                    {selectedVersion.temperature}
                  </div>
                </div>
                <Button
                  uppercase={false}
                  size="small"
                  color="purple"
                  variant="primary"
                  iconLeft="deliver"
                  onClick={() => setCreateNewTestRun(true)}
                >
                  {t('createTestRun')}
                </Button>
              </div>

              <Text size="xs" color="peanut">
                {selectedVersion.prompt}
              </Text>
            </>
          )}
        </div>
      </div>
      {createNewTestRun && (
        <NewRunModal selectedVersion={selectedVersion} onClose={() => setCreateNewTestRun(false)} />
      )}
    </>
  );
};

export default InsightHistory;
