import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GenerationInsight, DecisionInsight } from '@bloobirds-it/copilot';
import { Icon, Text } from '@bloobirds-it/flamingo-ui';
import { useCopilotActivity } from '@bloobirds-it/hooks';
import { Insight, InsightType, ACTIVITY_FIELDS_LOGIC_ROLE } from '@bloobirds-it/types';
import { api, getFieldByLogicRole } from '@bloobirds-it/utils';
import useSWR from 'swr';

import styles from './insights.module.css';
import NoResultsFound from './noResultsFound';

interface Props {
  activity: Bobject;
}

interface InsightProps {
  activity: Bobject;
  insight: Insight;
  insightDefinition: CoreInsightDefinition;
}

const insightComponents: Record<InsightType, (props: InsightProps) => JSX.Element> = {
  DECISION: DecisionInsight,
  GENERATION: GenerationInsight,
};

const Insights = ({ activity }: Props) => {
  const { t } = useTranslation();
  const { insights: data } = useCopilotActivity();
  const type = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
  const mainTypeField = getFieldByLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.MEETING_MAIN_TYPE);

  const { data: coreInsights } = useSWR<CoreInsightDefinition[]>(
    '/utils/service/copilot-insights',
    key => api.get<CoreInsightDefinition[]>(key).then(res => res.data),
  );

  const coreInsightsFiltered = coreInsights?.filter(insight => {
    // If `type` is not provided, include all insights
    if (!type) return true;

    // Check if `insight.activityType` is 'all' or matches `type.text` (case-insensitive)
    const activityTypeMatch = ['all', type.text?.toLocaleLowerCase()].includes(
      insight.activityType?.toLocaleLowerCase(),
    );

    // If `insight.activityType` does not match, exclude this insight
    if (!activityTypeMatch) return false;

    // If `insight.activitySubtype` is falsy or 'all', include this insight
    if (!insight.activitySubtype || insight.activitySubtype.toLocaleLowerCase() === 'all') {
      return true;
    }

    // If `mainTypeField.value` is not provided, include this insight
    if (!mainTypeField?.value) {
      return true;
    }

    // Finally, check if `mainTypeField.value` matches `insight.activitySubtype`
    return mainTypeField.value === insight.activitySubtype;
  });

  return (
    <div className={styles.insights}>
      <div className={styles.insightsTitle}>
        <div className={styles.titleSection}>
          <Icon name="snippet" color="purple" />
          <Text size="s" weight="heavy">
            {t('ai.aiAnalysis.insightsFor')}
          </Text>
        </div>
      </div>

      {coreInsightsFiltered && data?.insights?.length > 0 ? (
        data?.insights?.map(i => {
          const Insight = insightComponents[i.insight_type];
          const coreInsight = coreInsights.find(ci => ci.id === i.insightId);

          if (!coreInsight) {
            return null;
          }

          return (
            <Insight key={i.pk} activity={activity} insight={i} insightDefinition={coreInsight} />
          );
        })
      ) : (
        <NoResultsFound />
      )}
    </div>
  );
};

export default Insights;
