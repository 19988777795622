import { ACTIVITY_FIELDS_LOGIC_ROLE, Bobject } from '@bloobirds-it/types';
import { api, getTextFromLogicRole } from '@bloobirds-it/utils';
import { atom, useRecoilState, useResetRecoilState } from 'recoil';

import { CheckTestSet, CheckTestSetResponse } from '../typings/playground';

interface SendToPlaygroundModalState {
  isOpen: boolean;
  bobjects: null | Bobject[];
  typeMismatch: boolean;
  checkingActivities: boolean;
  checkedActivities: null | {
    activitiesWithTranscript: number;
    activitiesWithoutTranscript: number;
    invalidActivities: number;
  };
  type: string;
  validActivitiesCount: number;
}

const sendToPlaygroundModalAtom = atom<SendToPlaygroundModalState>({
  key: 'sendToPlaygroundModal',
  default: {
    isOpen: false,
    bobjects: null,
    typeMismatch: false,
    checkingActivities: false,
    checkedActivities: null,
    type: null,
    validActivitiesCount: 0,
  },
});

const allHaveSameType = (bobjects: Bobject[]) => {
  const targetType = getTextFromLogicRole(bobjects[0], ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
  return bobjects.every(bobject => {
    const type = getTextFromLogicRole(bobject, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
    return targetType === type;
  });
};

const useSendToPlaygroundModal = () => {
  const [state, setState] = useRecoilState(sendToPlaygroundModalAtom);
  const resetState = useResetRecoilState(sendToPlaygroundModalAtom);

  const closeSendToPlaygroundModal = () => {
    resetState();
  };

  const checkActivities = (data: CheckTestSet) => {
    return api
      .post<CheckTestSetResponse>('/copilot/playground/test-set/check', data)
      .then(res => res.data);
  };

  const openSendToPlaygroundModal = (bobjects: Bobject[]) => {
    const allMatchType = allHaveSameType(bobjects);

    const state: SendToPlaygroundModalState = {
      isOpen: true,
      bobjects,
      typeMismatch: !allMatchType,
      checkingActivities: true,
      checkedActivities: null,
      type: getTextFromLogicRole(bobjects[0], ACTIVITY_FIELDS_LOGIC_ROLE.TYPE),
      validActivitiesCount: 0,
    };

    const filterBySingleEmailThread = (bobjects: Bobject[]) => {
      if (!bobjects) return [];
      const type = getTextFromLogicRole(bobjects[0], ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);

      if (type !== 'Email') return bobjects;
      else {
        const threads: Record<string, boolean> = {};

        return bobjects.filter(bob => {
          const threadId = getTextFromLogicRole(bob, ACTIVITY_FIELDS_LOGIC_ROLE.EMAIL_THREAD_ID);

          if (!threadId || !threads[threadId]) {
            threads[threadId] = true;
            return true;
          } else return false;
        });
      }
    };

    checkActivities({
      activities: filterBySingleEmailThread(bobjects).map(b => b.id.objectId),
    }).then(checkRes =>
      setState({
        ...state,
        checkedActivities: {
          activitiesWithTranscript: checkRes.activitiesWithTranscript.length,
          activitiesWithoutTranscript: checkRes.activitiesWithoutTranscript.length,
          invalidActivities:
            checkRes.activitiesChecked.length -
            checkRes.activitiesWithTranscript.length -
            checkRes.activitiesWithoutTranscript.length,
        },
        checkingActivities: false,
        validActivitiesCount:
          checkRes.activitiesWithTranscript.length + checkRes.activitiesWithoutTranscript.length,
      }),
    );

    setState(state);
  };

  return {
    ...state,
    openSendToPlaygroundModal,
    closeSendToPlaygroundModal,
  };
};

export default useSendToPlaygroundModal;
