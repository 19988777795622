import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { EmailThreadDetail } from '@bloobirds-it/activity-feed';
import {
  MarkdownReader,
  ActivityType,
  TestRunStatus,
  TestSet,
  isTranscriptActivity,
} from '@bloobirds-it/copilot';
import { IconButton, Text, Skeleton, Icon } from '@bloobirds-it/flamingo-ui';
import { useUserSettings } from '@bloobirds-it/hooks';
import {
  ActivityTranscript,
  TranscriptFragment,
  DataModelResponse,
  ACTIVITY_FIELDS_LOGIC_ROLE,
} from '@bloobirds-it/types';
import { api, getTextFromLogicRole } from '@bloobirds-it/utils';
import useSWR from 'swr';

import styles from '../../../playgroundPages.module.css';
import SoundWave from './soundWave';
import useGetActivityAudio from './useGetActivityAudio';
import { InsightType } from './utils';

const ConversationBlock = ({ fragment }: { fragment: TranscriptFragment }) => {
  return (
    <div>
      <div className={styles.fragmentHeader}>
        <Text color="purple" size="xs" weight="bold">
          {/* @ts-ignore */}
          <Icon name={fragment.isLead ? 'person' : 'user'} color="purple" size={16} />
          {fragment.speaker} ({fragment.isLead ? 'lead' : 'user'})
        </Text>
      </div>

      <Text size="xxs">
        {fragment.text}
        <br />
      </Text>
    </div>
  );
};

const Label = ({
  active = true,
  children,
  onClick,
}: {
  active?: boolean;
  children: string;
  onClick?: () => void;
}) => {
  return (
    <div
      style={{
        background: !active ? '#E5ECF5' : '#464F57',
        color: !active ? '#94A5BA' : 'white',
      }}
      className={styles.testRun_activities_expanded_label}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

enum SelectedTabMode {
  'AUDIO',
  'SUMMARY',
  'TRANSCRIPT',
  'EMAIL',
}

interface IExpandedActivityRow {
  selectedTestSet: TestSet;
  status: TestRunStatus;
  insightType: InsightType;
  activityId: string;
  results: any;
  activityType: ActivityType;
  dataModel: DataModelResponse;
}
const ExpandedActivityRow = ({
  selectedTestSet,
  status,
  insightType,
  activityId,
  results,
  dataModel,
}: IExpandedActivityRow) => {
  const history = useHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.activityTab' });
  const [mode, setMode] = useState<SelectedTabMode>(() => {
    return status === 'COMPLETED' && insightType === InsightType.GENERATION
      ? SelectedTabMode.SUMMARY
      : SelectedTabMode.TRANSCRIPT;
  });
  const settings = useUserSettings();
  const userId = settings?.user?.id;
  const accountId = settings?.account?.id;

  const { activity } = useGetActivityAudio(`${accountId}/Activity/${activityId}`);
  const activityType = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.TYPE);
  const emailThreadId = getTextFromLogicRole(activity, ACTIVITY_FIELDS_LOGIC_ROLE.EMAIL_THREAD_ID);
  const { data: transcript, isLoading } = useSWR<ActivityTranscript>(
    activityId && `/copilot/transcript/call/${activityId}`,
    key => api.get<ActivityTranscript>(key).then(res => res.data),
  );

  const insight = selectedTestSet?.activities?.[activityId];

  const isLoadingInsights = !insight && !selectedTestSet;

  const isTranscriptAct = isTranscriptActivity(activityType);
  return (
    <tr className={styles.testRun_activities_expanded_container}>
      <td className={styles.testRun_activities_expanded}>
        {status === 'NOT_STARTED' || insightType === InsightType.DECISION ? (
          <>
            <Label>{t('summary')}</Label>
            <div className={styles.blocks}>
              {status === 'NOT_STARTED' ? (
                <Text size="xs">{t('summaryInfo', { activityType })}</Text>
              ) : isLoadingInsights ? (
                Array.from({ length: 7 }).map((_, index) => (
                  <Skeleton key={index} variant="text" width="100%" height={30} />
                ))
              ) : (
                <Text size="xs">{insight?.summary}</Text>
              )}
            </div>
          </>
        ) : (
          <MarkdownReader text={results} />
        )}
      </td>
      <td className={styles.testRun_activities_expanded}>
        <div className={styles.testRun_activities_expanded_title}>
          {status === 'COMPLETED' && insightType === InsightType.GENERATION && (
            <Label
              active={mode === SelectedTabMode.SUMMARY}
              onClick={() => setMode(SelectedTabMode.SUMMARY)}
            >
              {t('summary')}
            </Label>
          )}
          {isTranscriptAct && (
            <Label
              active={mode === SelectedTabMode.TRANSCRIPT}
              onClick={() => setMode(SelectedTabMode.TRANSCRIPT)}
            >
              {t('transcript')}
            </Label>
          )}
          {activityType === 'Email' && (
            <Label
              active={mode === SelectedTabMode.EMAIL}
              onClick={() => setMode(SelectedTabMode.EMAIL)}
            >
              Email
            </Label>
          )}
          {activity?.recordCall && (
            <Label
              active={mode === SelectedTabMode.AUDIO}
              onClick={() => {
                setMode(SelectedTabMode.AUDIO);
              }}
            >
              Audio
            </Label>
          )}
          {activity?.botId && (
            <IconButton
              name="externalLink"
              size={16}
              color="softPurple"
              onClick={() => {
                const newRoute = history.createHref({
                  pathname: `/app/ai-analysis/meeting/${activityId}`,
                });
                window.open(newRoute, '_blank');
              }}
            />
          )}
        </div>
        <div className={styles.blocks}>
          {mode === SelectedTabMode.SUMMARY &&
            (isLoadingInsights ? (
              Array.from({ length: 7 }).map((_, index) => (
                <Skeleton key={index} variant="text" width="100%" height={30} />
              ))
            ) : (
              <Text size="xs">{insight?.summary}</Text>
            ))}
          {mode === SelectedTabMode.TRANSCRIPT &&
            isTranscriptAct &&
            (isLoading
              ? Array.from({ length: 7 }).map((_, index) => (
                  <Skeleton key={index} variant="text" width="100%" height={30} />
                ))
              : transcript?.transcript?.map((fragment, index) => (
                  <ConversationBlock key={index} fragment={fragment} />
                )))}
          {mode === SelectedTabMode.AUDIO && <SoundWave id={activityId} activityAudio={activity} />}
          {mode === SelectedTabMode.EMAIL && (
            <EmailThreadDetail
              activity={activity}
              accountId={accountId}
              dataModel={dataModel}
              emailThreadId={emailThreadId}
              visibleFooter={false}
              actionsDisabled
              userId={userId}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default ExpandedActivityRow;
