import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconButton, IconType } from '@bloobirds-it/flamingo-ui';
import {
  CopilotActivityType,
  CopilotInsight,
  DataModelResponse,
  PicklistField,
  TestRun,
  TestSet,
} from '@bloobirds-it/types';
import { api } from '@bloobirds-it/utils';
import clsx from 'clsx';
import useSWR from 'swr';

import styles from '../../../playgroundPages.module.css';
import ExpandedActivityRow from './expandedActivityRow';
import ExpectedValue from './expectedValue';
import ResultCell from './resultCell';
import { generateDecisionChips, getResult, InsightType } from './utils';

const ACTIVITY_TYPE_ICON: Record<CopilotActivityType, IconType> = {
  Email: 'mail',
  Call: 'voicemail',
  Meeting: 'voicemail',
};

interface IActivityRowProps {
  activityId: string;
  selectedRun: TestRun;
  selectedInsight: CopilotInsight;
  selectedTestSet: TestSet;
  dataModel: DataModelResponse;
}

const ActivityRow = ({
  activityId,
  selectedRun,
  selectedInsight,
  selectedTestSet,
  dataModel,
}: IActivityRowProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.activityTab' });
  const [expanded, setExpanded] = useState(false);

  const activityResults = selectedRun?.results[activityId];
  const hasError = activityResults?.error || activityResults?.result_tag === 'FAILURE_PROCESSING';

  const { data: picklistField } = useSWR<PicklistField>(
    `/utils/picklists/${selectedInsight?.activityField}/type`,
    key => api.get<PicklistField>(key).then(res => res.data),
  );

  let results;
  if (selectedInsight?.insightType === InsightType.DECISION) {
    results = generateDecisionChips(activityResults, picklistField);
  } else if (selectedInsight?.insightType === InsightType.GENERATION) {
    results = activityResults?.result;
  }
  if (hasError) {
    results = t('error') + activityResults?.error;
  }

  const buttonClasses = clsx(styles.testRun_activities_results_icon, {
    [styles.expanded]: expanded,
  });

  const rowClasses = clsx(styles.testRun_activities_row, {
    [styles.error]: hasError,
  });

  const isDecision = selectedInsight?.insightType === InsightType.DECISION;
  const isGeneration = selectedInsight?.insightType === InsightType.GENERATION;

  const classes = clsx(styles.testRun_activities_results, {
    [styles.ellipsis]: isGeneration,
  });

  return (
    <>
      <tr key={activityId} className={rowClasses}>
        <td>
          <div className={buttonClasses}>
            <IconButton
              name={expanded ? 'chevronUp' : 'chevronDown'}
              size={20}
              color={expanded ? 'white' : 'lightPurple'}
              onClick={() => setExpanded(v => !v)}
            />
          </div>
        </td>
        <td className={classes}>
          {selectedRun.status === 'NOT_STARTED' && <>{t('resultsNotStarted')}</>}
          {selectedRun.status === 'COMPLETED' &&
            (isDecision || !expanded) &&
            (results || t('processing'))}
          {selectedRun.status === 'COMPLETED' && isGeneration && expanded && t('compareResults')}
        </td>
        {selectedRun.status === 'NOT_STARTED' && isGeneration && (
          <td className={styles.testRun_activities_result_right}>
            {t('evaluateResultsNotStarted')}
          </td>
        )}
        {isDecision && !hasError && (
          <td>
            <div className={styles.testRun_activities_results}>
              <ExpectedValue
                activityResults={activityResults}
                activityId={activityId}
                insightId={selectedInsight?.id}
                picklistField={picklistField}
                testSetId={selectedRun?.test_set_id}
              />
            </div>
          </td>
        )}
        {(selectedRun.status !== 'NOT_STARTED' || isDecision) && (
          <td className={styles.testRun_activities_result_status}>
            <ResultCell
              hasError={hasError}
              result={getResult(activityResults?.result_tag)}
              insightType={selectedInsight?.insightType}
              activityResults={activityResults}
              selectedRunId={selectedRun.pk}
              testSetId={selectedRun.test_set_id}
            />
          </td>
        )}
      </tr>
      {expanded && (
        <ExpandedActivityRow
          key={activityId}
          selectedTestSet={selectedTestSet}
          status={selectedRun.status}
          insightType={selectedInsight?.insightType}
          activityId={activityId}
          results={results || t('processing')}
          dataModel={dataModel}
        />
      )}
    </>
  );
};

export default ActivityRow;
