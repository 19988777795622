import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Tooltip } from '@bloobirds-it/flamingo-ui';
import {
  Bobject,
  BobjectId,
  BobjectTypes,
  GroupedActivityInterface,
  MainBobjectTypes,
  MessagesEvents,
  RelatedBobjectApiId,
  TaskFeedTask,
} from '@bloobirds-it/types';
import { getReferencedBobject } from '@bloobirds-it/utils';
import classnames from 'clsx';

import { useGetMainObjects } from '../../hooks/useGetMainObjects';
import { getRelatedTypeFromComposedId } from '../../utils/relatedObjects.utils';
import styles from './relatedObjectName.module.css';

interface Props<T extends Bobject | TaskFeedTask | GroupedActivityInterface> {
  relatedObjectId: RelatedBobjectApiId[];
  bobject: T;
  withDivider?: boolean;
  setActiveRelatedContext?: (
    bobjectId: BobjectId<BobjectTypes.Activity | BobjectTypes.Task>['value'],
    referenceBobjectId: BobjectId<
      BobjectTypes.Company | BobjectTypes.Lead | BobjectTypes.Opportunity
    >['value'],
    uploadContext?: boolean,
  ) => void;
  closeExtendedScreen?: () => void;
  blockOnClick?: boolean;
}

type RelatedBojbectType = BobjectTypes.Activity | BobjectTypes.Task;

function getIds(
  bobject: Bobject<RelatedBojbectType> | TaskFeedTask | GroupedActivityInterface,
): {
  bobjectId: BobjectId<RelatedBojbectType>['value'];
  referenceBobjectId: BobjectId<MainBobjectTypes>['value'];
} {
  if (!bobject) return { bobjectId: null, referenceBobjectId: null };
  if ('bobjectId' in bobject) {
    return {
      bobjectId: bobject.bobjectId,
      referenceBobjectId: bobject.opportunityId || bobject.leadId || bobject.companyId,
    };
  }
  if ('id' in bobject && typeof bobject.id === 'string') {
    return {
      bobjectId: bobject.id,
      referenceBobjectId: bobject.opportunity?.id || bobject.lead?.id || bobject.company?.id,
    };
  }
  return {
    bobjectId: bobject.id?.value,
    referenceBobjectId: getReferencedBobject(bobject)?.id?.value as BobjectId<
      MainBobjectTypes
    >['value'],
  };
}

export const RelatedObjectNameText = <
  T extends Bobject<RelatedBojbectType> | TaskFeedTask | GroupedActivityInterface =
    | Bobject<RelatedBojbectType>
    | TaskFeedTask
    | GroupedActivityInterface
>({
  relatedObjectId,
  bobject,
  withDivider = false,
  blockOnClick = false,
  setActiveRelatedContext,
  closeExtendedScreen,
}: Props<T>) => {
  const { getIconByRelatedType } = useGetMainObjects();
  const ref = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('translation', { keyPrefix: 'relatedObjects.common' });

  if (!relatedObjectId || relatedObjectId?.length === 0) return null;
  const { bobjectId, referenceBobjectId } = getIds(bobject);

  const relatedObjectIdToUse =
    typeof relatedObjectId === 'string' ? relatedObjectId : relatedObjectId?.[0];
  const relatedType = getRelatedTypeFromComposedId(relatedObjectIdToUse);
  const relatedIcon = getIconByRelatedType(relatedObjectIdToUse);

  const onCardClick = () => {
    if (blockOnClick) return;
    window.dispatchEvent(new CustomEvent(MessagesEvents.ForceOpenExtension));
    if (bobjectId && referenceBobjectId) {
      setActiveRelatedContext?.(bobjectId, referenceBobjectId);
    } else {
      throw new Error('No bobject or referenced to set active related');
    }
    closeExtendedScreen?.();
  };

  return relatedObjectId && relatedType ? (
    <div className={styles._wrapper} ref={ref}>
      {relatedObjectId && withDivider && <div className={styles.divider} />}
      <Tooltip
        title={ref?.current?.clientWidth < 100 ? t('relatedWithA') + relatedType : undefined}
        position="top"
      >
        <span
          data-test={`Span-${relatedObjectId}`}
          className={classnames(styles._container)}
          onClick={event => {
            event.preventDefault();
            event.stopPropagation();
            onCardClick();
          }}
        >
          {relatedIcon && <Icon name={relatedIcon} size={14} />} {t('relatedWithA') + relatedType}
        </span>
      </Tooltip>
    </div>
  ) : null;
};
