import React from 'react';
import { useTranslation } from 'react-i18next';

import { Icon, Skeleton, Tooltip } from '@bloobirds-it/flamingo-ui';
import { useDataModel } from '@bloobirds-it/hooks';
import { CopilotInsight, TestRun, TestRunStatus, TestSet } from '@bloobirds-it/types';

import styles from '../../../playgroundPages.module.css';
import ActivityRow from './activityRow';
import { LoadingResults } from './loadingResults';

interface IActivityTabProps {
  selectedInsight: CopilotInsight;
  selectedRun: TestRun;
  selectedTestSet: TestSet;
}
export function ActivityTab({ selectedInsight, selectedRun, selectedTestSet }: IActivityTabProps) {
  const insightType = selectedInsight?.insightType;
  const { t } = useTranslation('translation', { keyPrefix: 'ai.playground.activityTab' });
  const datamodel = useDataModel();

  return selectedRun?.status === TestRunStatus.RUNNING ? (
    <LoadingResults testRun={selectedRun} />
  ) : (
    <table className={styles.testRun_activities}>
      <thead>
        <tr>
          <th style={{ width: '32px' }} />
          <th className={styles.testRun_activities_results}>
            {t('suggestedValue')}{' '}
            {selectedRun.status === 'NOT_STARTED' && (
              <Tooltip title={t('suggestedValueTooltip')} position="top">
                <Icon
                  size={16}
                  name="infoFilled"
                  color="darkBloobirds"
                  className={styles._info_icon}
                />
              </Tooltip>
            )}
          </th>
          {insightType === 'DECISION' && <th>{t('realValue')}</th>}
          <th>{t('result')}</th>
        </tr>
      </thead>
      <tbody>
        {selectedRun?.results
          ? Object.keys(selectedRun?.results).map(activityId => {
              return (
                <ActivityRow
                  key={activityId}
                  activityId={activityId}
                  selectedRun={selectedRun}
                  selectedInsight={selectedInsight}
                  selectedTestSet={selectedTestSet}
                  dataModel={datamodel}
                />
              );
            })
          : Array.from({ length: 10 }).map((_, index) => (
              <Skeleton height={18} width="100%" key={index} />
            ))}
      </tbody>
    </table>
  );
}
